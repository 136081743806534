var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background-custom",style:(("background-image: url('" + _vm.imgUrl + "');"))},[_c('b-container',{staticClass:"auth-wrapper-3"},[_c('b-row',{staticClass:"justify-content-center middle",attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"d-flex align-items-center auth-bg-white px-2 p-lg-5",attrs:{"lg":"10"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto my-2",attrs:{"sm":"12","md":"8","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bolder mb-1",attrs:{"title-tag":"h1"}},[_vm._v(" "+_vm._s(_vm.$t('register.title'))+" ")]),_c('div',{staticStyle:{"width":"10%"}},[_c('hr',{staticClass:"linea"})]),_c('b-row',[_c('b-col',{},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$t('register.subtitle')))]),_c('b-link',{attrs:{"to":{ name: 'login' }}},[_vm._v(" "+_vm._s(_vm.$t('Inicio de sesion'))+" ")])],1)],1),_c('validation-observer',{ref:"createUser"},[_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-1"},[_c('ImageDropzone',{ref:"images",attrs:{"files":_vm.files}}),_c('b-form-group',{attrs:{"label":_vm.$t('Nombre'),"label-for":"register-name"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Nombre'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-name","state":errors.length > 0 ? false : null,"name":"register-name","placeholder":_vm.$t('Nombre')},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Apellidos'),"label-for":"register-surname"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Nombre'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-surname","state":errors.length > 0 ? false : null,"name":"register-surname","placeholder":_vm.$t('Apellidos')},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","state":errors.length > 0 ? false : null,"name":"register-email","placeholder":"john@example.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"register-password"}},[_vm._v(_vm._s(_vm.$t('Contraseña')))])]),_c('validation-provider',{attrs:{"name":_vm.$t('Contraseña'),"rules":"required|min:6","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{ref:"password",staticClass:"form-control-merge",attrs:{"id":"password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"passwordconfirmed"}},[_vm._v(_vm._s(_vm.$t('ConfirmarContraseña')))])]),_c('validation-provider',{attrs:{"name":_vm.$t('Contraseña'),"rules":"confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"passwordconfirmed","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"passwordconfirmed","placeholder":"············"},model:{value:(_vm.passwordRepeat),callback:function ($$v) {_vm.passwordRepeat=$$v},expression:"passwordRepeat"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"docs"}},[_vm._v(_vm._s(_vm.$t('Selecciona con qué rol deseas acceder al área privada'))+":")])]),_c('validation-provider',{attrs:{"name":"role","rules":"required|atLeastOne"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"tarificador","value":"user"},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Gestor de pedidos')))]),_c('br'),_c('small',[_vm._v(_vm._s(_vm.$t('Serás gestor de documentos y también podrás solicitar presupuestos, aceptarlos y ver el seguimiento de tus pedidos')))])]),_c('b-form-checkbox',{attrs:{"name":"documental","value":"documental"},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Gestor de documentos')))]),_c('br'),_c('small',[_vm._v(_vm._s(_vm.$t('Podrás visualizar y descargar documentación técnica de nuestros productos')))])]),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio',{attrs:{"aria-describedby":ariaDescribedby,"name":"some-radios","value":"true"},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}},[_vm._v(" "+_vm._s(_vm.$t('register.AlreadyClient'))+" ")]),_c('b-form-radio',{attrs:{"aria-describedby":ariaDescribedby,"name":"some-radios","value":"false"},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}},[_vm._v(" "+_vm._s(_vm.$t('register.NotClient'))+" ")])]}}])}),_c('b-form-group',{attrs:{"label":_vm.$t('Razon social'),"label-for":"register-name-client"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Razon social'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-name-client","state":errors.length > 0 ? false : null,"name":"register-name-client","placeholder":_vm.$t('Razon social')},model:{value:(_vm.ClientName),callback:function ($$v) {_vm.ClientName=$$v},expression:"ClientName"}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('CIF'),"label-for":"register-cif-client"}},[_c('validation-provider',{attrs:{"name":_vm.$t('CIF'),"rules":"required|validateCIF"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-cif-client","state":errors.length > 0 ? false : null,"name":"register-cif-client","placeholder":_vm.$t('CIF')},model:{value:(_vm.ClientCif),callback:function ($$v) {_vm.ClientCif=$$v},expression:"ClientCif"}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1),(_vm.client == 'false')?_c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('Tipo de empresa'),"label-for":"register-tipo-empresa"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Tipo de empresa'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.selectTypeCompany,"filterable":true,"searchable":true,"placeholder":_vm.$t('Tipo de empresa')},model:{value:(_vm.tipoEmpresa),callback:function ($$v) {_vm.tipoEmpresa=$$v},expression:"tipoEmpresa"}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1301067985)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Telefono'),"label-for":"register-phone-client"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Telefono'),"rules":"required|min:9|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-phone-client","state":errors.length > 0 ? false : null,"name":"register-phone-client","placeholder":_vm.$t('Telefono')},model:{value:(_vm.ClientPhone),callback:function ($$v) {_vm.ClientPhone=$$v},expression:"ClientPhone"}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,219470004)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Pais'),"label-for":"register-country-client"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Pais'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"register-country-client","value":_vm.ClientCountry,"state":errors.length > 0 ? false : null,"options":_vm.listCountries,"label":"name","clearable":false,"name":"register-country-client","placeholder":_vm.$t('Pais')},on:{"input":_vm.handlerClientCountry}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3599882698)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Direccion'),"label-for":"register-address-client"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Direccion'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-address-client","state":errors.length > 0 ? false : null,"name":"register-address-client","placeholder":_vm.$t('Direccion')},model:{value:(_vm.ClientAddress),callback:function ($$v) {_vm.ClientAddress=$$v},expression:"ClientAddress"}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,372878396)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('CodigoPostal'),"label-for":"register-cp-client"}},[_c('validation-provider',{attrs:{"name":_vm.$t('CodigoPostal'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-cp-client","state":errors.length > 0 ? false : null,"name":"register-cp-client","placeholder":_vm.$t('CodigoPostal')},model:{value:(_vm.ClientCP),callback:function ($$v) {_vm.ClientCP=$$v},expression:"ClientCP"}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1291996215)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Municipio'),"label-for":"register-town-client"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Municipio'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-town-client","state":errors.length > 0 ? false : null,"name":"register-town-client","placeholder":_vm.$t('Municipio')},model:{value:(_vm.ClientTown),callback:function ($$v) {_vm.ClientTown=$$v},expression:"ClientTown"}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1666560601)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Provincia'),"label-for":"register-province-client"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Provincia'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.ClientCountry.code === 'ES')?_c('v-select',{attrs:{"label":"name","options":_vm.selectProvinces,"filterable":true,"searchable":true,"placeholder":_vm.$t('Provincia'),"disabled":!_vm.ClientCountry},model:{value:(_vm.ClientProvince),callback:function ($$v) {_vm.ClientProvince=$$v},expression:"ClientProvince"}}):_c('b-form-input',{attrs:{"placeholder":_vm.$t('Provincia'),"disabled":!_vm.ClientCountry},model:{value:(_vm.ClientProvince),callback:function ($$v) {_vm.ClientProvince=$$v},expression:"ClientProvince"}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2668107073)})],1)],1):_vm._e(),_c('b-form-checkbox',{attrs:{"required":"","checked":false,"name":"politica","inline":""},model:{value:(_vm.politica),callback:function ($$v) {_vm.politica=$$v},expression:"politica"}},[_c('span',[_vm._v(_vm._s(_vm.$t('register.PoliticaPrivacidad')))]),_c('b-link',{attrs:{"href":"https://tecnitexfire.com/politica-privacidad/","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('politica de privacidad'))+" ")])],1),_c('b-col',{staticClass:"p-0"},[_c('b-button',{staticClass:"mt-2",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('register.CreateAccount'))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }