<template>
  <div class="background-custom" :style="`background-image: url('${imgUrl}');`">
    <b-container class="auth-wrapper-3">
      <b-row class="justify-content-center middle" align-v="center">
        <b-col lg="10" class="d-flex align-items-center auth-bg-white px-2 p-lg-5">
          <b-col sm="12" md="8" lg="12" class="px-xl-2 mx-auto my-2">
            <b-card-title title-tag="h1" class="font-weight-bolder mb-1">
              {{ $t('register.title') }}
            </b-card-title>
            <div style="width: 10%">
              <hr class="linea">
            </div>
            <b-row>
              <b-col class="">
                <span class="mt-2"> {{ $t('register.subtitle') }}</span>
                <b-link :to="{ name: 'login' }">
                  {{ $t('Inicio de sesion') }}
                </b-link>
              </b-col>
            </b-row>

            <!-- form -->
            <validation-observer ref="createUser">
              <b-form class="auth-register-form mt-2" @submit.prevent="handleSubmit" @keydown.enter="handleSubmit">
                <b-row>
                  <b-col class="mb-1">
                    <ImageDropzone ref="images" :files="files" />
                    <!-- name -->
                    <b-form-group :label="$t('Nombre')" label-for="register-name">
                      <validation-provider #default="{ errors }" :name="$t('Nombre')" rules="required">
                        <b-form-input id="register-name" v-model="userName" :state="errors.length > 0 ? false : null"
                          name="register-name" :placeholder="$t('Nombre')" />
                        <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group :label="$t('Apellidos')" label-for="register-surname">
                      <validation-provider #default="{ errors }" :name="$t('Nombre')" rules="required">
                        <b-form-input id="register-surname" v-model="surname" :state="errors.length > 0 ? false : null"
                                      name="register-surname" :placeholder="$t('Apellidos')" />
                        <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- email -->
                    <b-form-group :label="$t('Email')" label-for="register-email">
                      <validation-provider #default="{ errors }" :name="$t('Email')" rules="required|email">
                        <b-form-input id="register-email" v-model="userEmail" :state="errors.length > 0 ? false : null"
                          name="register-email" placeholder="john@example.com" />
                        <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- password -->
                    <b-form-group>
                      <div class="d-flex justify-content-between">
                        <label for="register-password">{{ $t('Contraseña') }}</label>
                      </div>
                      <validation-provider #default="{ errors }" :name="$t('Contraseña')" rules="required|min:6" vid="password">
                        <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                          <b-form-input id="password" v-model="password" ref="password"
                            :state="errors.length > 0 ? false : null" class="form-control-merge" :type="passwordFieldType"
                            name="password" placeholder="············" />
                          <b-input-group-append is-text>
                            <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                              @click="togglePasswordVisibility" />
                          </b-input-group-append>
                        </b-input-group>
                        <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- repeat password -->
                    <b-form-group>
                      <div class="d-flex justify-content-between">
                        <label for="passwordconfirmed">{{ $t('ConfirmarContraseña') }}</label>
                      </div>
                      <validation-provider #default="{ errors }" :name="$t('Contraseña')" rules="confirmed:password">
                        <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                          <b-form-input id="passwordconfirmed" v-model="passwordRepeat"
                            :state="errors.length > 0 ? false : null" class="form-control-merge" :type="passwordFieldType"
                            name="passwordconfirmed" placeholder="············" />
                          <b-input-group-append is-text>
                            <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                              @click="togglePasswordVisibility" />
                          </b-input-group-append>
                        </b-input-group>
                        <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group>
                      <div class="d-flex justify-content-between">
                        <label for="docs">{{ $t('Selecciona con qué rol deseas acceder al área privada') }}:</label>
                      </div>
                      <!-- check gestor pedidos-->
                      <validation-provider #default="{ errors }" name="role" rules="required|atLeastOne">
                        <b-form-checkbox v-model="role" name="tarificador" value="user">
                          <span>{{ $t('Gestor de pedidos') }}</span><br/>
                          <small>{{ $t('Serás gestor de documentos y también podrás solicitar presupuestos, aceptarlos y ver el seguimiento de tus pedidos') }}</small>
                        </b-form-checkbox>

                        <b-form-checkbox v-model="role" name="documental" value="documental">
                          <span>{{ $t('Gestor de documentos') }}</span><br/>
                          <small>{{ $t('Podrás visualizar y descargar documentación técnica de nuestros productos') }}</small>
                        </b-form-checkbox>

                        <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group v-slot="{ ariaDescribedby }">
                      <b-form-radio v-model="client" :aria-describedby="ariaDescribedby" name="some-radios" value="true">
                        {{ $t('register.AlreadyClient') }}
                      </b-form-radio>
                      <b-form-radio v-model="client" :aria-describedby="ariaDescribedby" name="some-radios" value="false">
                        {{ $t('register.NotClient') }}
                      </b-form-radio>
                    </b-form-group>

                    <!-- nombre client -->
                    <b-form-group :label="$t('Razon social')" label-for="register-name-client">
                      <validation-provider #default="{ errors }" :name="$t('Razon social')" rules="required">
                        <b-form-input id="register-name-client" v-model="ClientName"
                          :state="errors.length > 0 ? false : null" name="register-name-client"
                          :placeholder="$t('Razon social')" />
                        <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <!-- cif client -->
                    <b-form-group :label="$t('CIF')" label-for="register-cif-client">
                      <validation-provider #default="{ errors }" :name="$t('CIF')" rules="required|validateCIF">
                        <b-form-input id="register-cif-client" v-model="ClientCif"
                          :state="errors.length > 0 ? false : null" name="register-cif-client" :placeholder="$t('CIF')" />
                        <small ref="errorField" class="text-danger">{{ $t(errors[0]) }}</small>
                      </validation-provider>
                    </b-form-group>

                    <div v-if="client == 'false'">
                      <!-- tipo de empresa -->
                      <b-form-group :label="$t('Tipo de empresa')" label-for="register-tipo-empresa">
                        <validation-provider #default="{ errors }" :name="$t('Tipo de empresa')" rules="required">
                          <v-select v-model="tipoEmpresa" :options="selectTypeCompany" :filterable="true"
                                    :searchable="true" :placeholder="$t('Tipo de empresa')" />
                          <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- phone client -->
                      <b-form-group :label="$t('Telefono')" label-for="register-phone-client">
                        <validation-provider #default="{ errors }" :name="$t('Telefono')" rules="required|min:9|numeric">
                          <b-form-input id="register-phone-client" v-model="ClientPhone"
                            :state="errors.length > 0 ? false : null" name="register-phone-client"
                            :placeholder="$t('Telefono')" />
                          <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- pais client -->
                      <b-form-group :label="$t('Pais')" label-for="register-country-client">
                        <validation-provider #default="{ errors }" :name="$t('Pais')" rules="required">
                          <v-select
                            id="register-country-client"
                            @input="handlerClientCountry"
                            :value="ClientCountry"
                            :state="errors.length > 0 ? false : null"
                            :options="listCountries"
                            label="name"
                            :clearable="false"
                            name="register-country-client"
                            :placeholder="$t('Pais')"
                          />
                          <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- dirección client -->
                      <b-form-group :label="$t('Direccion')" label-for="register-address-client">
                        <validation-provider #default="{ errors }" :name="$t('Direccion')" rules="required">
                          <b-form-input id="register-address-client" v-model="ClientAddress"
                            :state="errors.length > 0 ? false : null" name="register-address-client"
                            :placeholder="$t('Direccion')" />
                          <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- cp client -->
                      <b-form-group :label="$t('CodigoPostal')" label-for="register-cp-client">
                        <validation-provider #default="{ errors }" :name="$t('CodigoPostal')" rules="required">
                          <b-form-input id="register-cp-client" v-model="ClientCP"
                            :state="errors.length > 0 ? false : null" name="register-cp-client"
                            :placeholder="$t('CodigoPostal')" />
                          <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- town client -->
                      <b-form-group :label="$t('Municipio')" label-for="register-town-client">
                        <validation-provider #default="{ errors }" :name="$t('Municipio')" rules="required">
                          <b-form-input id="register-town-client" v-model="ClientTown"
                            :state="errors.length > 0 ? false : null" name="register-town-client"
                            :placeholder="$t('Municipio')" />
                          <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <!-- province client -->
                      <b-form-group :label="$t('Provincia')" label-for="register-province-client">
                        <validation-provider #default="{ errors }" :name="$t('Provincia')" rules="required">
                          <v-select
                            v-if="ClientCountry.code === 'ES'"
                            v-model="ClientProvince"
                            label="name"
                            :options="selectProvinces"
                            :filterable="true"
                            :searchable="true"
                            :placeholder="$t('Provincia')"
                            :disabled="!ClientCountry"
                          />
                          <b-form-input
                            v-else
                            v-model="ClientProvince"
                            :placeholder="$t('Provincia')"
                            :disabled="!ClientCountry"
                          />
                          <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <b-form-checkbox v-model="politica" required :checked="false" name="politica" inline>
                      <span>{{ $t('register.PoliticaPrivacidad') }}</span>
                      <b-link href="https://tecnitexfire.com/politica-privacidad/" target="_blank">
                        {{ $t('politica de privacidad') }}
                      </b-link>
                    </b-form-checkbox>
                    <b-col class="p-0">
                      <b-button type="submit" variant="primary" class="mt-2">
                        {{ $t('register.CreateAccount') }}
                      </b-button>
                    </b-col>
                  </b-col>
                </b-row>
                <!-- submit buttons -->
              </b-form>
            </validation-observer>
          </b-col>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardTitle, BForm, BButton, BContainer, BFormRadio,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { mapActions, mapGetters } from 'vuex'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import { $themeConfig } from '../../themeConfig'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BContainer,
    BFormCheckbox,
    BCardTitle,
    BForm,
    BButton,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    ImageDropzone,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      passwordRepeat: '',
      userName: '',
      surname: '',
      ClientName: '',
      ClientPhone: '',
      ClientCountry: '',
      ClientAddress: '',
      ClientCP: '',
      ClientTown: '',
      ClientProvince: '',
      ClientCif: '',
      userEmail: '',
      avatar: '',
      politica: false,
      sideImg: require('@/assets/images/pages/login/login.png'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      rememberme: false,
      showForgotPassword: false,
      client: true,
      files: [],
      // campos gestor documental
      tipoEmpresa: null,
      role: '',
      // tarificador: false,
      // documental: false,
    }
  },
  computed: {
    ...mapGetters({
      countries: 'countries/getCountries',
      selectProvinces: 'provinces/getSelectProvinces',
      selectTypeCompany: 'config/getSelectTypeCompany',
      currentLanguage: 'languages/getCurrentLanguage',
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login/login.png')
        return this.sideImg
      }
      return this.sideImg
    },
    listCountries () {
      return this.countries.map((countrie) => {
        countrie.name = countrie.title[this.currentLanguage]
        return countrie
      })
    }
  },
  methods: {
    ...mapActions({
      getListCountries: 'countries/getListCountries',
      getSelectProvinces: 'provinces/selectProvinces',
      getSelectTypeCompany: 'config/getSelectTypeCompany',
      create: 'users/create',
    }),
    handleSubmit() {
      this.$refs.createUser.validate().then(success => {
        if (success) {
          const formData = this.createFormData()
          console.log(formData)
          if (formData) {
            this.create({ user: formData })
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
          block: 'end',
          });
        }
      })
    },
    createFormData() {
      const data = this.$refs.images.getFormData()
      data.append('name', this.userName)
      data.append('surname', this.surname)
      data.append('email', this.userEmail)
      data.append('password', this.password)
      data.append('client_name', this.ClientName)
      data.append('client_cif', this.ClientCif)

      if (this.client === 'false') {
        data.append('create_client', true)
        data.append('client_phone', this.ClientPhone)
        data.append('client_country_id', this.ClientCountry.id)
        data.append('client_address', this.ClientAddress)
        data.append('client_postalCode', this.ClientCP)
        data.append('client_province', this.ClientCountry.code === 'ES' ? this.ClientProvince.id : this.ClientProvince)
        data.append('client_town', this.ClientTown)
        data.append('client_type', this.tipoEmpresa)
      } else {
        data.append('create_client', false)
      }

      data.append('role', this.role)
      data.append('lang', this.currentLanguage)

      return data
    },
    handlerClientCountry(value) {
      this.ClientCountry = value
      this.ClientProvince = ''
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  async created() {
    await this.getListCountries({
      page: 1, per_page: 9999, search: '', orderBy: '',
    })
    await this.getSelectTypeCompany()
    await this.getSelectProvinces({
      page: 1, per_page: 9999, search: '', orderBy: '',
    })
    extend('atLeastOne', {
      message: this.$t('validations.atLeastOne'),
      validate: () => this.role !== false,
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
